<template>
  <div class="preview">
    <s-dialog :open="true" size="md" to="stds-dialog-product-preview">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[93.2rem]">
        <st-dialog-header @clickClose="onClose">
          <span class="!font-bold">{{ $t('studio.ai_translation.title1_settings') }}</span>
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <div v-if="!isLoading">
              <p class="text-sm leading-md text-on-surface-elevation-3 -mt-4">
                <safe-html :html="$t('studio.ai_translation.guide1')" />
              </p>

              <div class="flex items-end mt-12">
                <div class="w-[calc(50%+2.4rem)]">
                  <div class="flex items-center pr-48">
                    <p class="text-md font-medium leading-sm text-on-surface-elevation-2">
                      {{ $t('studio.ai_translation.base_lang') }}
                    </p>
                    <span
                      class="ml-8 rounded-full bg-[#ECF0F3] px-[.6rem] h-[2.2rem] flex items-center text-2xs font-medium leading-xs text-gray500"
                    >
                      {{ $t(`${getTabName(sourceLang.value)}`) }}
                    </span>
                    <s-tooltip
                      arrow
                      :content="$t('studio.ai_translation.base_lang_tool_tip')"
                      :duration="0"
                      :distance="4"
                      useFlip
                      flipOnUpdate
                      placement="bottom"
                      trigger="mouseenter focus"
                      :theme="'studio-tooltip'"
                      :zIndex="2501"
                      :allowHTML="true"
                    >
                      <template #target>
                        <s-icon
                          icon="ic-v2-state-info-circle-line"
                          size="xl"
                          class="text-on-surface-elevation-4 flex ml-4"
                        />
                      </template>
                    </s-tooltip>
                  </div>
                  <div class="mt-8 flex flex-col gap-20 mb-8">
                    <div class="flex flex-col gap-4">
                      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
                        {{
                          page === MULTILANGUAGE_DIALOG_NAME.EARLY_ACCESS_FORM
                            ? $t('studio.ai_translation.title10_early')
                            : $t('studio.ai_translation.title9_terms')
                        }}
                      </p>
                      <div class="flex items-start">
                        <div class="flex-1 custom-width-tab-editor">
                          <text-editor
                            v-model="content"
                            :config="editorConfig"
                            :projectId="projectId"
                            :readonly="readonly"
                            disabled
                            class="disabled-text-editor h-[42.2rem]"
                          />
                        </div>
                        <s-tooltip
                          arrow
                          :content="$t('studio.ai_translation.badge')"
                          :duration="0"
                          :distance="4"
                          useFlip
                          flipOnUpdate
                          placement="top"
                          trigger="mouseenter focus"
                          :theme="'studio-tooltip'"
                          :zIndex="2501"
                          :allowHTML="true"
                        >
                          <template #target>
                            <button
                              :disabled="disabled || !isProductWritable"
                              type="button"
                              class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                              @click="translate(currentLanguage)"
                            >
                              <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                            </button>
                          </template>
                        </s-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[calc(50%-2.4rem)]">
                  <s-tabs
                    v-model="currentLanguage"
                    defaultValue="basic-tab"
                    variant="line-fixed"
                    size="sm"
                    :defaultIndex="0"
                    class="stove-studio-tab-line"
                  >
                    <s-tab-list>
                      <s-tab-item
                        v-for="lang in availableLanguages"
                        :key="lang.value"
                        :value="lang.value"
                      >
                        <span class="break-words">{{ $t(getTabName(lang.value)) }}</span><s-icon
                          v-if="wordCount.find((w: WordCount) => w.lang === lang.value).count > wordCountLimit()"
                          size="xl"
                          icon="ic-v2-state-warning-circle-fill"
                          class="text-error ml-2"
                        />
                      </s-tab-item>
                    </s-tab-list>
                    <s-tab-panels>
                      <s-tab-panel
                        v-for="(lang, index) in availableLanguages"
                        :key="lang.id"
                        :value="lang.value"
                      >
                        <div class="mt-8 flex flex-col gap-16 mb-8">
                          <div
                            class="dark:bg-surface-variant-1"
                            :class="{
                              'absolute z-[9999] !h-full !w-full !max-h-full top-0 left-0 bg-background-variant-2 p-32':
                                isFullPage,
                              'w-full custom-width-tab-editor': !isFullPage
                            }"
                          >
                            <div class="mb-16 text-right flex shrink-0 items-center gap-16">
                              <h2
                                v-if="isFullPage"
                                class="text-xl font-bold leading-lg text-on-surface-elevation-1"
                              >
                                {{
                                  page === MULTILANGUAGE_DIALOG_NAME.TERM_FORM
                                    ? $t('studio.ai_translation.title9_terms')
                                    : $t('studio.prj_prod.this_prod.home_early_access_title')
                                }}
                              </h2>
                              <div
                                v-if="!(disabled || !isProductWritable)"
                                class="ml-auto flex shrink-0 items-center gap-16"
                              >
                                <s-button
                                  variant="outline"
                                  icon="ic-v2-media-av-fullscreen-fill"
                                  size="xs"
                                  :isDisabled="isCodeViewList[index]"
                                  @click="isFullPage = !isFullPage"
                                >
                                  {{
                                    isFullPage
                                      ? $t(
                                        'studio.prj_prod_mngmt.prod_home.create_term.btn_exit_fullscr'
                                      )
                                      : $t(
                                        'studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr'
                                      )
                                  }}
                                </s-button>
                              </div>
                            </div>
                            <!-- 에디터 -->
                            <text-editor
                              v-model="translatedTerm(lang).content"
                              :toolbarButtons="
                                page === MULTILANGUAGE_DIALOG_NAME.TERM_FORM
                                  ? TOOLBAR_BUTTON_TERMS
                                  : TOOLBAR_BUTTONS_FROALA
                              "
                              :config="editorConfig"
                              :projectId="projectId"
                              :class="
                                isFullPage ? '!h-full !w-full !max-h-full pb-32' : 'h-[42.2rem]'
                              "
                              :disabled="disabled || !isProductWritable"
                              :readonly="readonly"
                              :wordCountLimit="wordCountLimit()"
                              :charCounterMax="wordCountLimit()"
                              @onClickBtnHtmlCodeBlock="isCodeViewList[index] = $event"
                              @wordCount="handleWordCountUpdate($event, lang.value)"
                            />
                          </div>
                        </div>
                      </s-tab-panel>
                    </s-tab-panels>
                  </s-tabs>
                </div>
              </div>
              <div v-if="isMessageVisible" class="pl-[calc(50%+2.4rem)]">
                <p
                  class="flex items-start gap-[.2rem] mt-[.3rem] text-error text-xs leading-xs font-regular"
                >
                  <s-icon
                    icon="ic-v2-state-warning-circle-fill"
                    size="xl"
                    class="flex h-16 mt-[.1rem]"
                  />
                  {{ $t('studio.common.def_key.exceed', { length: wordCountLimit() }) }}
                </p>
              </div>
            </div>
            <div
              v-show="isLoading"
              class="flex flex-col justify-center items-center gap-20 pb-24"
              :style="{ height: loadingHeight }"
            >
              <s-circular-progress size="lg" />
              <p class="text-center text-lg font-medium leading-lg text-on-surface-elevation-3">
                <safe-html :html="$t('studio.ai_translation.progress_wait_msg')" />
              </p>
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            variant="outline"
            :isDisabled="disabled || !isProductWritable"
            icon="ic-v2-communication-translate-line"
            @click="translateAll"
          >
            {{ $t('studio.ai_translation.all_btn') }}
          </s-button>
          <s-button :isDisabled="disabled || !isProductWritable" @click="handleSaveTranslation">
            {{ $t('studio.common.popup_case_save_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-product-preview" />
  </div>
</template>

<script setup lang="ts">
import { SButton, SIcon, STooltip } from '@stove-ui/vue';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { translateApi } from '@/apis/translation.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { showConfirm } from '@/composables/useDialog';
import { TOOLBAR_BUTTON_TERMS, TOOLBAR_BUTTONS_FROALA } from '@/constants/froala.const';
import { MULTILANGUAGE_DIALOG_NAME } from '@/constants/terms.const';
import { TranslationFormatType } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import type { Language } from '@/types/common/common.type';
import type { TranslatedContent } from '@/types/terms/terms-response.type';
import type { Translated } from '@/types/translation/translation.response';
import { generateUUID } from '@/utils/uuid.util';

interface Translation {
  id?: number;
  lang: string;
  content: string;
}

interface WordCount {
  lang: string;
  count: number;
}

const EARLY_ACCESS_WORD_COUNT_LIMIT = 7000;
const TERMS_WORD_COUNT_LIMIT = 14000;

const props = withDefaults(
  defineProps<{
    content: string;
    translatedContent: TranslatedContent[];
    page: string;
    sourceLang: Language;
    projectId: string;
    disabled?: boolean;
    readonly?: boolean;
  }>(),
  {
    disabled: false
  }
);

const emits = defineEmits<{
  close: [v?: Translation[]];
}>();

const appStore = useAppStore();
const productStore = useProductStore();

const { t } = useI18n();

const { languages } = storeToRefs(appStore);
const { isProductWritable } = storeToRefs(productStore);

const { translatedContent, content, page } = toRefs(props);

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: '',
  height: '42.2rem'
};

const translatedTerm = (lang: Language) => {
  return (
    translatedContent.value.find(
      (t: Translation) => t.lang.toLowerCase() === lang.value.toLowerCase()
    ) ?? { lang, content: '' }
  );
};

const loadingHeight = ref<string>('auto');

const isLoading = ref<boolean>(false);
const isFullPage = ref<boolean>(false);

const isCodeViewList = ref<boolean[]>([]);

const formattedLanguages = computed(() => {
  return languages.value.map((lang: Language) => ({
    ...lang,
    value: lang.value.toLowerCase()
  }));
});

const availableLanguages = computed(() => {
  const lang = formattedLanguages.value.find(
    (lang: Language) => lang.value.toLowerCase() === props.sourceLang.value
  );

  if (lang) {
    const missingLanguages = translatedContent.value.find(
      (content: TranslatedContent) =>
        !formattedLanguages.value.find(
          (lang: Language) => lang.value.toLowerCase() === content.lang.toLowerCase()
        )
    )?.lang;

    const defaultLangs = formattedLanguages.value.filter(
      (l: Language) => l.value !== lang.value.toLowerCase()
    );

    if (!missingLanguages) {
      return defaultLangs;
    }

    const newLanguage: Language = {
      id: generateUUID(),
      name:
        Object.keys(LanguageCode).find(
          (key: string) => LanguageCode[key as keyof typeof LanguageCode] === missingLanguages
        ) ?? '',
      value: missingLanguages
    };

    defaultLangs.push(newLanguage);

    return defaultLangs;
  }

  return formattedLanguages.value;
});

const currentLanguage = ref<string>(availableLanguages.value[0].value ?? '');
const wordCount = ref<WordCount[]>(
  availableLanguages.value.map((lang: Language) => ({ lang: lang.value, count: 0 }))
);

const isMessageVisible = computed(() => {
  let isVisible = false;
  wordCount.value.forEach((w: { lang: string; count: number }) => {
    if (w.count > wordCountLimit()) {
      isVisible = true;
    }
  });
  return isVisible;
});

const wordCountLimit = () => {
  if (page.value === MULTILANGUAGE_DIALOG_NAME.TERM_FORM) {
    return TERMS_WORD_COUNT_LIMIT;
  }

  return EARLY_ACCESS_WORD_COUNT_LIMIT;
};

const handleWordCountUpdate = (wCount: number, lang: string) => {
  const count = wordCount.value.find((w: { lang: string; count: number }) => w.lang === lang);

  if (count) {
    count.count = wCount;
  }
};

const getTabName = (lang: string) => {
  if (lang.toLowerCase() === 'ko') {
    return 'studio.ai_translation.base_lang_ko';
  }
  return `studio.ai_translation.base_lang_${lang.toLowerCase().replace('-', '_')}`;
};

const translateAll = async () => {
  if (!props.content) {
    return;
  }

  let confirm = true;
  if (translatedContent.value.some((t: Translation) => t.content !== '')) {
    confirm = await showAlreadyEnteredContentAlert();
  }

  if (confirm) {
    isLoading.value = true;
    const res = await translateApi({
      sourceLang: props.sourceLang.value,
      targetLangs: translatedContent.value.map((t: Translation) => t.lang),
      contents: {
        text: content.value
      },
      formats: {
        text: TranslationFormatType.HTML
      }
    });
    isLoading.value = false;

    res?.translated.forEach((translation: Translated) => {
      const translated = translatedContent.value.find(
        (t: Translation) => t.lang === translation.lang
      );
      if (translated) {
        translated.content = translation.translatedContents.text;
      }
    });
  }
};

// Translate content to selected language
const translate = async (lang: string) => {
  if (!lang) {
    return;
  }

  const translation = translatedContent.value.find((t: Translation) => t.lang === lang);
  if (!translation) {
    return;
  }

  isLoading.value = true;
  const res = await translateApi({
    sourceLang: props.sourceLang.value,
    targetLangs: [lang],
    contents: {
      text: content.value
    },
    formats: {
      text: TranslationFormatType.HTML
    }
  });
  isLoading.value = false;

  res?.translated.forEach((translation: Translated) => {
    const translated = translatedContent.value.find(
      (t: Translation) => t.lang === translation.lang
    );
    if (translated) {
      translated.content = translation.translatedContents.text;
    }
  });
};

const showAlreadyEnteredContentAlert = async () => {
  return await showConfirm<boolean>({
    content: t('studio.common.popup_case_ai_overwrite'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmLabel: t('studio.common.popup_case_translation_in_progress_btn'),
    cancelVariant: 'outline'
  });
};

const handleSaveTranslation = () => {
  // Save translation content if word count is not exceeded
  if (isMessageVisible.value) {
    return;
  }

  emits('close', translatedContent.value);
};

const getHeight = () => {
  loadingHeight.value = `${
    document.querySelector('.stds-dialog-content-body')?.clientHeight ?? 0
  }px`;
};

const onClose = () => {
  if (isLoading.value) {
    return;
  }

  emits('close');
};

onMounted(() => {
  nextTick(() => {
    getHeight();
  });
});
</script>

<style scoped>
.disabled-text-editor .fr-wrapper {
  border: none;
}

.custom-width-tab-editor {
  max-width: 410px;
}
</style>
